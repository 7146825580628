import styled, { css } from 'styled-components'

const fieldCss = css`
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.75rem;
  border: 1px solid #dcdfe4;
  resize: none;
  vertical-align: top;
  outline: 0;
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media (min-width: 940px) {
    padding: 1rem;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:focus {
    border-color: #0099ff;
    background-color: #fff;
  }

  ${(props) =>
    props.half &&
    css`
      width: calc(50% - 0.375rem);
      float: left;

      &:first-child {
        margin-right: 0.75rem;
      }
    `}
`

export default styled.input`
  ${fieldCss};
`

export const Textarea = styled.textarea`
  ${fieldCss};
`

export const Select = styled.select`
  ${fieldCss};
  background: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzODYuMjU3IDM4Ni4yNTciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4Ni4yNTcgMzg2LjI1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiPgo8cG9seWdvbiBwb2ludHM9IjAsOTYuODc5IDE5My4xMjksMjg5LjM3OSAzODYuMjU3LDk2Ljg3OSAiIGZpbGw9IiMwMDAwMDAiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==')
    95% / 2.5% no-repeat #fff;
`

export const Label = styled.label`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 1rem;
`
