import styled, { css } from 'styled-components'

export default styled.section`
  margin: 100px auto;
  overflow: hidden;
  width: 1260px;
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1260px) {
    width: 90%;

    /* .gatsby-image-wrapper {
      width: 190px !important;
      height: 190px !important;
    } */
  }
  @media (max-width: 860px) {
    width: 100%;

    /* .gatsby-image-wrapper {
      width: 300px !important;
      height: 300px !important;
    } */
  }
  @media (max-width: 640px) {
    /* .gatsby-image-wrapper {
      width: 500px !important;
      height: 500px !important;
    } */
  }
  @media (max-width: 520px) {
    /* .gatsby-image-wrapper {
      width: 320px !important;
      height: 320px !important;
    } */
  }
`
