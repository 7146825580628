import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea, Select } from './Input'

export const Form = styled.form`
  max-width: 650px;
  flex-basis: 75%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-width: 45%;
  h2 {
    margin-bottom: 0.5rem !important;
    font-weight: 400;
    color: ${(props) => props.theme.colors.primary};
  }
  p {
    color: ${(props) => props.theme.colors.dark};
    margin: 0 auto 0.5rem !important;
    padding-right: 0 !important
    ;
    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const Fieldset = styled.div`
  /* margin: -0.375rem; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  /* @media (min-width: 940px) {
    margin: -0.5rem;
  } */
`

interface Props {
  children?: React.ReactChild
  title?: string
  didFindOut?: boolean
  showMessage?: boolean
  showSuburb?: boolean
  content?: string
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  static defaultProps = {
    showMessage: true,
    content: '',
    didFindOut: false,
    showSuburb: true,
  }

  state = {
    buttonMessage: `Send enquiry`,
    email: '',
    message: '',
    name: '',
    phone: '',
    suburb: '',
    didFindOut: '',
    website: '',
    buttonDisabled: false,
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ buttonMessage: 'Working...', buttonDisabled: true })

    fetch('/.netlify/functions/send-email', {
      body: JSON.stringify(this.state),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error()
        }
        this.setState({
          buttonMessage: 'Thank You!',
          buttonDisabled: true,
        })
        return true
      })
      .catch(() => {
        this.setState({
          buttonMessage: 'Error - Try Again!',
          buttonDisabled: false,
        })
      })

    return true
  }

  render() {
    const { type, title, showMessage, showSuburb, didFindOut } = this.props
    const isAudit = type === 'audit'

    return (
      <Form name="contactForm" method="post" onSubmit={this.handleSubmit}>
        {title && <h2>{title}</h2>}

        {this.props.content && (
          <p
            style={{ padding: '20px 0', fontSize: '1.2rem' }}
            dangerouslySetInnerHTML={{ __html: this.props.content }}
          />
        )}
        <Fieldset>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            required={true}
            value={this.state.name}
            onChange={this.handleChange}
          />

          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email address"
            required={true}
            value={this.state.email}
            onChange={this.handleChange}
          />
          <div>
            <Input
              half={showSuburb}
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone number"
              required={true}
              value={this.state.phone}
              onChange={this.handleChange}
            />
            {showSuburb && (
              <Input
                half={true}
                type="text"
                id="suburb"
                name="suburb"
                placeholder="Suburb"
                required={true}
                value={this.state.suburb}
                onChange={this.handleChange}
              />
            )}
          </div>

          <Textarea
            id="message"
            name="message"
            placeholder="Message"
            required={true}
            rows={showMessage ? 3 : didFindOut ? 4 : 1}
            onChange={this.handleChange}
            value={this.state.message}
          />

          {didFindOut && (
            <Select
              id="didFindOut"
              name="didFindOut"
              rows={showMessage ? 3 : 1}
              onChange={this.handleChange}
              value={this.state.didFindOut}
            >
              <option value="">How did you find out about us?</option>
              <option value="Internet">Internet</option>
              <option value="Magazine">Magazine</option>
              <option value="Brochure">Brochure</option>
              <option value="Friend">Friend</option>
              <option value="Homeshow">Homeshow</option>
              <option value="Newspaper">Newspaper</option>
              <option value="Television">Television</option>
              <option value="Radio">Radio</option>
              <option value="Other">Other</option>
            </Select>
          )}

          <Box my={3}>
            <Button
              small={true}
              full={true}
              inverted={true}
              type="submit"
              disabled={this.state.buttonDisabled}
              onSubmit={this.handleSubmit}
            >
              {this.state.buttonMessage}
            </Button>
          </Box>
        </Fieldset>
      </Form>
    )
  }
}
